import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import IMG from "../assets/1.png";
import { submitCat } from "../api/submit-data";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Form = () => {
  const [catName, setCatName] = useState("");
  const [tweetURL, setTweetURL] = useState("");
  const [youtubeURL, setYouTubeURL] = useState("");
  const [telegramURL, setTelegramURL] = useState("");

  const [logo, setLogo] = useState();
  //loading
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");

  // error
  const [error, setError] = useState("");
  const [imgError, setImgError] = useState("");

  const scanImg = (file) => {
    setImgError(null);

    if (!file.name.match(/\.(jpg|jpeg|png|gif|webp)$/)) {
      setImgError("Image File not Supported, Please ReUpload");
      return;
    }
    if (file.size > 1000000) {
      setImgError("Image File Too Large, Please ReUpload (1mb Max)");
      return;
    }
    setLogo(file);
  };

  function validateTweetLink(url) {
    const pattern =
      /^https?:\/\/(www\.)?(twitter\.com|x\.com)\/[^/]+\/status\/\d+$/;
    return pattern.test(url);
  }

  const submitForm = async () => {
    setError(null);

    if (!catName || !tweetURL) {
      setError("Cat Name and Tweet URL are required for submission");
      return;
    }

    if (!validateTweetLink(tweetURL)) return setError("Invalid Tweet URL");

    setLoading(true);

    //submittion to database
    const response = await submitCat(
      catName,
      tweetURL,
      youtubeURL,
      telegramURL,
      logo
    );

    if (!response) {
      setError("Unable to submit cat entry.");
      return;
    }

    setSuccess("Cat entry successfully submitted");
    setLoading(false);
  };

  return (
    <div className="max-w-6xl mx-auto">
      <div className="rounded-lg p-6 shadow-lg switch">
        <div className="text-center text-3xl my-20 ">Submit Cat </div>

        <div className="flex justify-center items-center flex-col  mt-5">
          <div className="avatar">
            <div className="ring-primary ring-offset-base-100 w-60 rounded-full ring ring-offset-2 animate-bounce">
              {logo ? (
                <img src={URL.createObjectURL(logo)} />
              ) : (
                <img src={IMG} />
              )}
            </div>
          </div>

          <div className="mt-5 space-x-10">
            <input
              type="file"
              className="file-input file-input-bordered file-input-primary w-full max-w-xs"
              onChange={(event) => scanImg(event.target.files[0])}
            />
          </div>

          {imgError && (
            <>
              <div role="alert" className="alert alert-error max-w-2xl mt-5">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current shrink-0 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
                <span>{imgError}</span>
              </div>
            </>
          )}
        </div>

        <div className="block lg:flex lg:flex-row mt-5 gap-x-5">
          {/* Section 1 */}
          <div className="flex-1">
            <div className="flex flex-row mt-5">
              <span className="flex-1">Cat Name</span>
              <span className="text-red-400">Required*</span>
            </div>
            <div>
              <input
                type="text"
                placeholder="example : My Special Cat"
                className="input input-bordered input-md w-full"
                onChange={(event) => setCatName(event.target.value)}
              />
            </div>
          </div>
          {/* Section 2 */}
          <div className="flex-1">
            <div className="flex flex-row mt-5">
              <span className="flex-1">Social Media Link</span>
              <span className="text-red-400">Required*</span>
            </div>
            <div>
              <input
                type="text"
                className="input input-bordered input-md w-full"
                onChange={(event) => setTweetURL(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="block lg:flex lg:flex-row mt-5 gap-x-5">
          {/* Section 1 */}
          <div className="flex-1">
            <div className="flex flex-row mt-5">
              <span className="flex-1">Youtube LInk</span>
              <span className="text-white">Optional</span>
            </div>
            <div>
              <input
                type="text"
                className="input input-bordered input-md w-full"
                onChange={(event) => setYouTubeURL(event.target.value)}
              />
            </div>
          </div>
          {/* Section 2 */}
          <div className="flex-1">
            <div className="flex flex-row mt-5">
              <span className="flex-1">Telegram Link</span>
              <span className="text-white">Optional</span>
            </div>
            <div>
              <input
                type="text"
                className="input input-bordered input-md w-full"
                onChange={(event) => setTelegramURL(event.target.value)}
              />
            </div>
          </div>
        </div>

        <div>
          <div className="flex flex-row mt-5">
            <span className="flex-1">SOL wallet address</span>
            <span className="text-white">Optional</span>
          </div>
          <div>
            <input
              type="text"
              className="input input-bordered input-md w-full"
              onChange={(event) => setTweetURL(event.target.value)}
            />
          </div>
        </div>

        {error && (
          <div role="alert" className="alert alert-error my-10">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 shrink-0 stroke-current"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{error}</span>
          </div>
        )}

        {success && (
          <div role="alert" className="alert alert-success mt-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <span>{success}</span>
          </div>
        )}

        <div className="text-center">
          <button onClick={submitForm} className="btn btn-accent mx-auto mt-5">
            {loading && <span className="loading loading-spinner"></span>}
            Sumbit Cat
          </button>
        </div>
      </div>
    </div>
  );
};

export default Form;
