import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, db, storage } from "../db/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  addDoc,
  getDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { v4 } from "uuid";

export const submittedCats = async () => {
  let results;
  try {
    const docRef = doc(db, "cats", process.env.REACT_APP_CATS);
    const docSnap = await getDoc(docRef);
    results = { ...docSnap.data() };
  } catch (err) {
    console.log("error");
    return false;
  }
  const loopData = results.submitted;
  return loopData;
};

export const approvedCats = async () => {
  let results;
  try {
    const docRef = doc(db, "cats", process.env.REACT_APP_CATS_APPROVED);
    const docSnap = await getDoc(docRef);
    results = { ...docSnap.data() };
  } catch (err) {
    console.log("error");
    return false;
  }
  const loopData = results.cats;
  return loopData;
};




export const submitCat = async (
  catName,
  tweetURL,
  youtubeURL,
  telegramURL,
  logo
) => {
  const imageRef = ref(storage, `${catName}/${logo.name}`);

  const status = await uploadBytes(imageRef, logo)
    .then((snapshot) => {
      return snapshot;
    })
    .catch((error) => {
      return false;
    });

  //if their was an error in uploading
  if (!status) return false;

  // get the image URL
  const logoURL = await getDownloadURL(status.ref).then((url) => {
    return url;
  });

  const newArray = await submittedCats();

  let newCatArray = {
    catName,
    tweetURL,
    youtubeURL,
    telegramURL,
    logo: logoURL,
    id: v4(),
    date_added: Date.now(),
  };

  newArray.push(newCatArray);

  const docRef = doc(db, "cats", process.env.REACT_APP_CATS);

  try {
    await updateDoc(docRef, {
      submitted: newArray,
    });
  } catch (error) {
    console.log(error);
    return false;
  }

  return true;
};
