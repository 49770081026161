import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import SocialIcons from "./elements/socialIcons";
import HeroPcs from "./elements/heroPcs";
import IMG from "../assets/5.png";

const backgroundStyle = {
  content: "",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `url("https://static.wixstatic.com/media/1f3f2b_45e0862f90b549b2b8858d28a44b60cc~mv2.png/v1/fill/w_1920,h_1080,al_c,q_95,enc_auto/1f3f2b_45e0862f90b549b2b8858d28a44b60cc~mv2.png")`,
  backgroundSize: "cover", // or 'contain', depending on your needs
  backgroundPosition: "center", // or other values like 'top', 'bottom', 'left', 'right'
  opacity: 0.2, // Start with opacity 0
  transition: "opacity 2s", // Transition for the fade effect
  zIndex: -1, // Ensures the background is behind the content
};

const HeroSection = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div ref={ref}>
      <div />

      <div className="max-w-5xl mx-auto flex flex-col md:flex-row pt-24">
        {/* Section 1 */}

        <div>
          <p className="text-5xl italic">$PEIPEI CAT AI</p>

          <motion.p
            initial={{ opacity: 0, y: -200 }}
            animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : -200 }}
            transition={{
              duration: 1.5,
              ease: "easeOut",
              delay: 0.3,
              bounceDamping: 10,
              bounceStiffness: 600,
            }}
            className="text-3xl font-mono font-light leading-20"
          >
            We choose the King and Queen cats of the Solana network. It's time
            to accompany us on this journey. Will they give us 100X? Let's see
            together.
          </motion.p>

          {/* <p className="md:flex md:flex-row my-10 grid grid-cols-3 gap-3 md:gap-0"> 
            <SocialIcons
              url="https://static.wixstatic.com/media/1f3f2b_0078029a112449e5b8d712401bfbd5ac~mv2.png/v1/fill/w_160,h_160,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/telgrrgrgrgr.png"
              isInView={isInView}
              link="https://t.me/pei_pei_cat_ai"
            />

            <SocialIcons
              url="https://static.wixstatic.com/media/1f3f2b_8eaff3e5f42b45f88cf11d418e25d5e6~mv2.png/v1/fill/w_160,h_160,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/twitter%20(2).png"
              isInView={isInView}
              link="https://x.com/Peipeicatai100x"
            />

            <SocialIcons
              url="https://static.wixstatic.com/media/1f3f2b_8dd371e91c534eba8bf5836ed468e899~mv2.png/v1/fill/w_160,h_160,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/dextools%20(1).png"
              isInView={isInView}
              link="https://coinsult.net/projects/pei-pei-cat-ai/"
            />

            <SocialIcons
              url="https://static.wixstatic.com/media/1f3f2b_95d714edb8484668b50ec07fb8b4f394~mv2.png/v1/fill/w_160,h_160,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/etherscan.png"
              isInView={isInView}
            />
            <SocialIcons
              url="https://static.wixstatic.com/media/1f3f2b_614e05b6d3e84630b377c85d7ed33137~mv2.png/v1/fill/w_160,h_160,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/cm%20c.png"
              isInView={isInView}
            />
            <SocialIcons
              url="https://cdn.lunarcrush.com/raydium.png"
              isInView={isInView}
            />
          </p> */}
        </div>

        {/* Section 2 */}
        <motion.div
          initial={{ opacity: 0, y: 200 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 1.5,
            ease: "easeOut",
            delay: 0.3,
            bounceDamping: 10,
            bounceStiffness: 600,
          }}
        >
          <img src={IMG} className="my-auto" alt="Hero" />
        </motion.div>
      </div>

      <div className="max-w-5xl mx-auto">
        <div className="flex flex-col md:flex-row gap-10 mx-5 md:mx-0">
          <HeroPcs
            title="gemini"
            url="https://static.wixstatic.com/media/c267fc_811875ce9695420abb11c2f6f29e709d~mv2.png/v1/fill/w_86,h_84,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/gemini-dollar-gusd-logo.png"
            isInView={isInView}
          />
          <HeroPcs
            title=" binance"
            url="https://static.wixstatic.com/media/1f3f2b_58bf93c97a11404db3720ec6be4111ed~mv2.png/v1/fill/w_68,h_68,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Binance_Logo_svg.png"
            isInView={isInView}
          />
          <HeroPcs
            title="crypto.com"
            url="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQLaFHqeeSglcNFoOcsoEzTByLycyoyZhHMQA&s"
            isInView={isInView}
          />
        </div>

        <br />

        <div className="flex flex-col md:flex-row gap-10 mx-5 md:mx-0">
          <HeroPcs
            title="htx"
            url="https://static.wixstatic.com/media/1f3f2b_971d92d7687e4a94a2c4da6275ba91d8~mv2.png/v1/fill/w_68,h_68,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/huobi-token-ht-logo.png"
            isInView={isInView}
          />
          <HeroPcs
            title="bybit"
            url="https://static.wixstatic.com/media/c267fc_34c34cdbe3594d86b3c2bd1b426b7144~mv2.webp/v1/fill/w_58,h_58,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/bybit_logo-min.webp"
            isInView={isInView}
          />
          <HeroPcs
            title="okx"
            url="https://static.wixstatic.com/media/1f3f2b_f6704cbab81b42fea33b2c9039243e89~mv2.png/v1/fill/w_58,h_58,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/A60595EC12C04739-removebg-preview.png"
            isInView={isInView}
          />
        </div>

        <br />

        <div className="flex flex-col md:flex-row gap-10 mx-5 md:mx-0">
          <HeroPcs
            title=" cb wallet"
            url="https://static.wixstatic.com/media/c267fc_e66f9e3851df4765a0f16b7380420265~mv2.png/v1/fill/w_58,h_58,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/coinbase-coin-logo-C86F46D7B8-seeklogo_com.png"
            isInView={isInView}
          />
          <HeroPcs
            title=" kucoin"
            url="https://static.wixstatic.com/media/1f3f2b_13560e0364c64eb88dce4361e56d6c87~mv2.png/v1/fill/w_68,h_68,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/kucoin-logo.png"
            isInView={isInView}
          />
          <HeroPcs
            title="raydium"
            url="https://cdn.lunarcrush.com/raydium.png"
            isInView={isInView}
          />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
