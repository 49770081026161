import React, { useRef } from "react";

import { motion, useInView } from "framer-motion";
import IMG from "../assets/toko.png";

const SectionSeven = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div className="max-w-6xl mx-auto" ref={ref}>
      <h2 className="text-center text-6xl mt-24">TOKENOMICS</h2>

      <div className="flex flex-col md:flex-row my-20">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 100 }}
          transition={{
            duration: 1,
            ease: "linear",
            delay: 0.5,
          }}
          className="flex-1"
        >
          <img src={IMG} className="w-[70%] mx-auto" alt="Section Four" />
        </motion.div>

        <div className=" justify-center">
          <h2 className="text-3xl text-center font-mono">TOKENOMICS</h2>

          <div className=" my-10 border-2 border-white p-5 shadow-[#E1EDCF] shadow-2xl rounded-tr-3xl rounded-bl-3xl bg-black bg-opacity-50 p-12">
            <span className="text-white text-lg flex-1">
              Pinksale 54%
              <br />
              CEX 30%
              <br />
              TEAM 0%
              <br />
              Project DEVELOPMENT 16%
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSeven;
