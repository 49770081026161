import React from "react";
import IMG from "../assets/logo.png";

const Navbar = () => {
  return (
    <div className="shadow-2xl border-b-1 border-black">
      <div className="mx-auto max-w-5xl">
        <ul className="flex flex-row space-x-5 py-4 md:py-10">
          <li className="flex-1">
            <img src={IMG} className="w-36" alt="Logo" />
          </li>

          <a
            href="https://docs.google.com/document/d/1Mr2n86MkHTkFnf7nES6HbR-z4EUm0oO2cAOvH3HLZgE/edit"
            className="border border-1 border-white rounded-full px-10 my-auto py-3 flex-end hidden md:inline"
            target="_blank"
            rel="noreferrer"
          >
            <b className="my-auto text-white">Whitepaper</b>
          </a>

          <a
            href="https://docs.google.com/document/d/1AqGYmyQiW0ZDPDqjXS6JzSaqE5IhAwq0cyaYqJviJN8/edit#heading=h.z6ne0og04bp5"
            className="border border-1 border-white rounded-full px-10 my-auto py-3 flex-end hidden md:inline"
            target="_blank"
            rel="noreferrer"
          >
            <b className="my-auto text-white">Marketing</b>
          </a>
          <a
            href="https://linktr.ee/peipeicatai"
            className="border border-1 border-white rounded-full px-10 my-auto py-3 flex-end hidden md:inline"
            target="_blank"
            rel="noreferrer"
          >
            <b className="my-auto text-white">LinkTree</b>
          </a>
          <a
            href="#roadmap"
            className="border border-1 border-white rounded-full px-10 my-auto py-3 flex-end hidden md:inline"
            target="_blank"
            rel="noreferrer"
          >
            <b className="my-auto text-white">Roadmap</b>
          </a>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
