import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import IMG from "../assets/2.png";

const SectionTwo = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div className="max-w-5xl mx-auto my-36" ref={ref}>
      <div className="flex flex-col md:flex-row gap-10">
        <div className="flex-1">
          <motion.img
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : -100 }}
            transition={{
              duration: 1.5,
              ease: "linear",
              delay: 0.5,
            }}
            src={IMG}
            alt="Section Two"
            className="w-[100%]"
          />
        </div>

        <div className="flex-1">
          <motion.h2
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : -50 }}
            transition={{
              duration: 1.5,
              ease: "linear",
              delay: 0.5,
            }}
            className="text-center text-7xl"
          >
            about
          </motion.h2>

          <motion.div
            initial={{ opacity: 0, y: 100 }}
            animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 100 }}
            transition={{
              duration: 1.5,
              ease: "linear",
              delay: 0.5,
            }}
          >
            <p className="my-10 font-mono text-xl">
              $PPCI is tired of watching everyone play hot potato with the
              endless derivative ShibaCumGMElonKishuTurboAssFlokiMoon Inu coins.
              The Inu’s have had their day. It’s time for the most recognizable
              meme in the world to take his reign as king of the internet.
            </p>

            <p className="my-10 font-mono text-xl">
              $PPCI is here to make memecoins great again. Launched stealth with
              no presale, zero taxes, LP burnt and contract renounced, $PPCI is
              a coin for the people, forever. Fueled by pure memetic power, let
              $PPCI PEPE show you the way.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
