import React from "react";

const SectionFive = () => {
  return (
    <div className="max-w-6xl mx-auto my-20" id="roadmap">
      <div className="grid grid-cols-1 gap-5">
        <div>
          <h2 className="text-center text-4xl">ROADMAP</h2>

          <div className="text-center text-xl mt-5">Phase 1</div>
          <div className="mb-10 border-2 border-white p-5 shadow-[#E1EDCF] shadow-2xl rounded-tr-3xl rounded-bl-3xl bg-black bg-opacity-50 p-12 text-center">
            <span className="text-white text-lg flex-1">
              -Website Launch
              <br />
              -Community Building
              <br />
              -Fairlaunch on Pinksale
              <br />
              -Listing on Bullishmarketcap and Cex Gate
              <br />
              -Banner ads on Bullishmarketcap, Cex Gate
              <br />
              -Wordwide Marketing and Top Callers Ads
              <br />
              -Coinmarketcap and Coingecko Fastrack Listing Ordered
            </span>
          </div>

          <div className="text-center text-xl mt-5">Phase 2</div>
          <div className="mb-10 border-2 border-white p-5 shadow-[#E1EDCF] shadow-2xl rounded-tr-3xl rounded-bl-3xl bg-black bg-opacity-50 p-12 text-center">
            <span className="text-white text-lg flex-1">
              <p>
                -Launch on Raydium
                <br />
                -SOL TREND Buy bot ADS -Dexscener, Dexview, Dextool Update and
                Trend Booked
                <br />
                -Bullishmarketcap Trend-1Booked
                <br />
                -Cex Gate Trend
                <br />
                -1  Booked -Banner ads on Bullishmarketcap, Cex Gate
                <br />
                -Coinmarketcap and Coingecko Fastrack Listing
              </p>
            </span>
          </div>

          <div className="text-center text-xl mt-5">Phase 3</div>
          <div className=" mb-10 border-2 border-white p-5 shadow-[#E1EDCF] shadow-2xl rounded-tr-3xl rounded-bl-3xl bg-black bg-opacity-50 p-12 text-center">
            <span className="text-white text-lg flex-1">
              <p>
                - Nft Launch
                <br />
                - Royalties for NFT charities
                <br />
                -Expand Community Engagements
                <br />- New Partnerships
                <br />- PEI PEI CAT AI DAO CLUB
              </p>
            </span>
          </div>

          <div className="text-center text-xl mt-5">Phase 4</div>
          <div className=" mb-10 border-2 border-white p-5 shadow-[#E1EDCF] shadow-2xl rounded-tr-3xl rounded-bl-3xl bg-black bg-opacity-50 p-12 text-center">
            <span className="text-white text-lg flex-1">
              <p>
                -PEI PEI CAT AI Nft Marketplace
                <br />
                -Gamification and Rewards
                <br />
                -Mexc Listing
                <br />
                -Gateio Listing
                <br />
                -Kucoin Listing
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionFive;
