import React from "react";
import { motion } from "framer-motion";

const HeroPcs = ({ title, url, isInView }) => {
  return (
    <>
      <motion.span
        initial={{ scaleX: 0 }}
        animate={{ scaleX: isInView ? 1 : 0 }}
        transition={{
          duration: 0.7,
          ease: "linear",
          delay: 1,
          bounceDamping: 10,
          bounceStiffness: 600,
        }}
        className="hover:bg-white hover:text-black hover:cursor-pointer border border-1 rounded-full border-white px-10 py-2 text-lg md:text-4xl flex flex-1 justify-center"
      >
        <motion.b
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 2,
            bounceDamping: 10,
            bounceStiffness: 600,
          }}
        >
          {title}
        </motion.b>

        <motion.img
          initial={{ opacity: 0 }}
          animate={{ opacity: isInView ? 1 : 0 }}
          transition={{
            duration: 1,
            ease: "easeOut",
            delay: 2,
            bounceDamping: 10,
            bounceStiffness: 600,
          }}
          src={url}
          className="w-10 h-10 ml-4 animate-bounce rounded-full"
          alt="icons"
        />
      </motion.span>
    </>
  );
};

export default HeroPcs;
