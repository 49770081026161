import React, { useRef } from "react";
import { motion, useInView } from "framer-motion";
import Card from "./elements/card";

const styles = {
  border: 0,
  margin: "0 auto",
  marginBottom: "0.5rem",
  display: "block",
  borderRadius: "10px",
  maxWidth: "960px",
  minWidth: "600px",
};

const SectionThree = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div className="max-w-6xl mx-auto my-36" ref={ref}>
      <motion.h2
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : -50 }}
        transition={{
          duration: 1.5,
          ease: "linear",
          delay: 0.5,
        }}
        className="text-center text-7xl"
      >
        how to buy
      </motion.h2>

      <Card
        title="create a wallet"
        description="Download Phantom or your wallet of choice from the app store or google play store for free. Desktop users, download the google chrome extension by going to  Phantom."
        url="https://static.wixstatic.com/media/1f3f2b_af81019501c549d5b192536bb8680fc5~mv2.png/v1/fill/w_276,h_276,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Untitled%20design%20-%202023-03-30T212906_738.png"
        isInView={isInView}
        speed={25}
      />
      <Card
        title="Get Some SOL"
        description="have SOL in your wallet to switch to $PPCI. If you don’t have any SOL, you can buy directly on Phantom, transfer from another wallet, or buy on another exchange and send it to your wallet."
        url="https://static.wixstatic.com/media/1f3f2b_48f30828b7d740d98efb1a2f8febc010~mv2.png/v1/fill/w_276,h_276,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/Untitled%20design%20-%202023-03-30T213620_680.png"
        isInView={isInView}
        speed={50}
      />

      <Card
        title="Go to Raydium"
        description="connect to Phantom . Go to https://raydium.io/swap   in google chrome or on the browser inside your Metamask app. Connect your wallet. Paste the $PPCI token address into Raydium, select PPCI , and confirm. When Phantom prompts you for a wallet signature, sign."
        url="https://s2.coinmarketcap.com/static/img/coins/64x64/8526.png"
        isInView={isInView}
        speed={75}
      />

      <Card
        title="Switch SOL for $PPCI"
        description="Switch SOL for $PPCI. We have ZERO taxes so you don’t need to worry about buying with a specific slippage, although you may need to use slippage during times of market volatility."
        url="http://localhost:3000/static/media/2.cccf92946f8a914da054.png"
        isInView={isInView}
        speed={100}
      />
    </div>
  );
};

export default SectionThree;
