import React from "react";
import IMG from "../../assets/1.png";
import IMG1 from "../../assets/2.png";
import IMG2 from "../../assets/3.png";

const SliderImg = () => {
  return (
    <span className="flex flex-row my-20">
      <img src={IMG} className="w-6" alt="Marquee" />
      -
      <img src={IMG1} className="w-6" alt="Marquee" />
      -
      <img src={IMG2} className="w-6" alt="Marquee" />-
    </span>
  );
};

export default SliderImg;
