import React, { useEffect, useState } from "react";
import { approvedCats } from "../api/submit-data";
import IMG from "../assets/1.png";
import Marquee from "react-fast-marquee";

import Moment from "react-moment";

const UsersCats = () => {
  const [loading, setLoading] = useState(true);
  const [cats, setCats] = useState([]);

  const fetchData = async () => {
    const data = await approvedCats();
    if (!data) {
      setLoading(false);
      return;
    }

    setCats(data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="text-white mx-auto my-20 text-center">
        <img
          src={IMG}
          className="animate-bounce w-24 rounded-full border border-2 border-white mx-auto"
          alt="loading state"
        />
      </div>
    );
  }

  console.log(cats);

  return (
    <>
      <div className="text-center text-lg mb-10">
        Join PeiPei Cat AI Competition
      </div>

      <Marquee direction="left">
        {cats.map((cat, index) => (
          <div key={index} className="mx-3">
            <img
              src={cat.logo}
              alt={cat.catName}
              className="w-96 h-60 rounded-xl"
            />
            <h2>{cat.catName}</h2>
            <a
              href={cat.tweetURL}
              target="_blank"
              className="btn btn-accent btn-sm"
            >
              [Holder Link]
            </a>
            {/* <h1 className="text-sm">
              <Moment fromNow>{cat.date_added}</Moment>
            </h1> */}
          </div>
        ))}
      </Marquee>
    </>
  );
};

export default UsersCats;
