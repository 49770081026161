import React from "react";
import IMG from "../assets/3.png";

const Footer = () => {
  return (
    <div className="max-w-3xl mx-auto font-sans">
      <div className="flex flex-wrap gap-10 justify-center">
        <a href="https://x.com/Peipeicatai100x" target="_blank">
          <img
            src="https://static.wixstatic.com/media/1f3f2b_f59a557a4c72456581fa206a9247dcac~mv2.png/v1/fill/w_170,h_170,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/twtttt.png"
            alt="Footer"
            className="h-12 my-auto"
          />
        </a>

        <a href="https://t.me/PeiPeiCatAiofficial" target="_blank">
          <img
            src="https://cdn.pixabay.com/photo/2021/12/27/10/50/telegram-icon-6896828_960_720.png"
            alt="Footer"
            className="h-12 my-auto"
          />
        </a>

        <a href="https://medium.com/@peipeicatai100x" target="_blank">
          <img
            src="https://cdn-icons-png.flaticon.com/512/5968/5968906.png"
            alt="Footer"
            className="h-12 my-auto"
          />
        </a>

        <a href="https://t.me/pei_pei_catai" target="_blank">
          <img
            src="https://cdn.hottg.com/icon/Th/TheTelegramChatRoom.jpg"
            alt="Footer"
            className="h-12 my-auto rounded-full"
          />
        </a>
      </div>

      <p className="leading-10 text-center">
        $PPCI coin has no association with any other MEMECOIN. Our target select
        King and Queen Cats of SOLANA NETWORK.
      </p>

      <br />

      <p className="leading-10 text-center">
        We have experienced developers, Incubators, Marketing Agencies and more.
        Join us and let's succeed together.
      </p>
    </div>
  );
};

export default Footer;
