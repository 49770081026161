import Footer from "./components/footer";
import Form from "./components/form";
import HeroSection from "./components/hero-section";
import Navbar from "./components/navbar";
import PartnersSlider from "./components/partners-slider";
import SectionTwo from "./components/section-2";
import SectionThree from "./components/section-3";
import SectionFour from "./components/section-4";
import SectionFive from "./components/section-5";
import SectionSeven from "./components/section-7";
import Slider from "./components/slider";
import UsersCats from "./components/users-cats";

function App() {
  return (
    <div className="">
      <Navbar />

      <HeroSection />

      <PartnersSlider />

      <Slider />

      <UsersCats />

      <Slider />

      <SectionTwo />

      <Slider />

      <Form />

      <Slider />

      <SectionThree />

      <Slider />

      <SectionFour />

      <Slider />

      <SectionSeven />
      
      <Slider />

      <SectionFive />

      <Slider />

      <Footer />
    </div>
  );
}

export default App;
