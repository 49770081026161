import React, { useRef } from "react";

import { motion, useInView } from "framer-motion";
import IMG from "../assets/1.png";

const SectionFour = () => {
  const ref = useRef(null);
  const isInView = useInView(ref);

  return (
    <div className="max-w-6xl mx-auto" ref={ref}>
      <h2 className="text-center text-6xl mt-24">PeiPei Cat AI Prize</h2>

      <div className="flex flex-col md:flex-row my-20">
        <div className="flex-1 justify-center">
          <h2 className="text-3xl text-center font-mono">
            Competition PeiPei Cat AI Prize
          </h2>

          <div className=" my-10 border-2 border-white p-5 shadow-[#E1EDCF] shadow-2xl rounded-tr-3xl rounded-bl-3xl bg-black bg-opacity-50 p-12">
            <span className="text-white text-lg flex-1">
              King CAT Winner : 5 SOL
              <br />
              <br />
              Queen CAT Winner: 5 SOL
              <br />
              <br />
              Handsome 50 King CAT Assistant Winner: Each one will win 1 SOL
              <br />
              <br />
              Beautiful 50 Queen CAT Assistant Winner: Each one will win 1 SOL
            </span>
          </div>
        </div>

        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : 100 }}
          transition={{
            duration: 1,
            ease: "linear",
            delay: 0.5,
          }}
          className="flex-1"
        >
          <img src={IMG} className="w-[70%] mx-auto" alt="Section Four" />
        </motion.div>
      </div>
    </div>
  );
};

export default SectionFour;
